<div class="w-100 d-flex flex-fill">
    <div class="user-meals">
        <div *ngxPermissionsOnly="'canViewAppUserContactInfo'" class="section">
                <div>
                  <div style="margin-top: 30px;margin-right: 30px;" class="d-flex justify-content-end" *ngxPermissionsOnly="'canAssignPremiumFeaturesToAppUsers'">
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="openDialog()"
                    >
                        <span class="text-white">{{
                          "MOBILE_USERS.ADD_NOTES" | translate
                          }}</span>
                    </button>
                  </div>
                    <div style="margin-right: 30px;">
                      <mat-form-field appearance="standard">
                        <mat-label>Filter</mat-label>
                        <input class="m-1" matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
                      </mat-form-field>
                      
                      <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort>
                      
                          <!-- Title Column -->
                          <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                            <td mat-cell *matCellDef="let row"> {{row.title}} </td>
                          </ng-container>
                      
                          <!-- Notes Column -->
                          <ng-container matColumnDef="notes">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Notes </th>
                            <td mat-cell *matCellDef="let row"> {{row.notes}}</td>
                          </ng-container>
                          <!-- Date Column -->
                          <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                            <td mat-cell *matCellDef="let row"> {{row.date | date}}</td>
                          </ng-container>
                          <!-- Action Column -->
                          <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                            <td mat-cell *matCellDef="let row">
                              <button mat-button color="warn" (click)="uploadDocument(row)">
                                Upload Document
                              </button>
                              <button mat-icon-button color="accent" (click)="editNote(row)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              <button mat-icon-button color="warn" (click)="deleteNote(row.id)">
                                <mat-icon>delete</mat-icon>
                              </button>
                            </td>
                          </ng-container>
                          <tr style="background-color: #1A1A27;" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      
                          <!-- Row shown when there is no matching data. -->
                          <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                          </tr>
                        </table>
                      
                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
                      </div>                      
                    </div>
                </div>
             
        </div>
      </div>
</div>
