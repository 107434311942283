<div>
  <p>{{ "SHARED.CONFIRM_MESSAGE" | translate }}</p>
  <div class="d-flex justify-content-center">
    <button
      class="margin-end-2"
      mat-raised-button
      color="warn"
      (click)="close(true)"
    >
      {{ "SHARED.CONFIRM" | translate }}
    </button>
    <button mat-raised-button (click)="close(false)">
      {{ "SHARED.CANCEL" | translate }}
    </button>
  </div>
</div>
