<!-- <div>
    <div class="mat-elevation-z8 list-container">
      <app-list-header
        [permissions]="'canCreateBlogs'"
        [searchTerm]="search$"
        [withAddButton]="true"
        (addClicked)="this.router.navigateByUrl('Hunger-videos/add')"
        headerTitle="{{ 'Hunger Videos' | translate }}"
      >
      </app-list-header>
  
      <div *ngIf="hungerVideos$ | async; let hungerVideos">
        <table mat-table [dataSource]="hungerVideos" class="w-100">
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>
              {{ "BLOG_VIDEOS.SUBJECT" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.subject }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="tag">
            <th mat-header-cell *matHeaderCellDef>
              {{ "BLOG_VIDEOS.TAG" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                class="tag-preview"
                [style.background-color]="element.tag.color"
              >
                {{ element.tag.name }}
              </span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>
              {{ "SHARED.CREATED" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.created | date: "dd-MM-YYYY" }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="mat-row-hover"
            (click)="this.router.navigateByUrl('/Hunger-videos/edit/' + row.id)"
          ></tr>
        </table>
        <app-no-data-placeholder classes="my-3" [data]="hungerVideos"> </app-no-data-placeholder>
      </div>
  
      <mat-paginator
        #paginator
        [length]="totalRows$ | async"
        [pageSize]="10"
        [pageSizeOptions]="[1, 5, 10, 25, 100]"
        (page)="loadHungerVideos()"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
    </div>
  </div> -->
  

   <div>
    <div class="mat-elevation-z8 list-container">
      <app-list-header
        [permissions]="'canCreateBlogs'"
        [withAddButton]="true"
        (addClicked)="this.router.navigateByUrl('Hunger-videos/add')"
        headerTitle="{{ 'Hunger Videos' | translate }}"
      >
      </app-list-header>
  
      <div *ngIf="dataSource.data.length; else noData">
        <table mat-table [dataSource]="dataSource" class="w-100">
          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef>
              {{ "ARTICLES.SUBJECT" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.subject }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="hungerType">
            <th mat-header-cell *matHeaderCellDef>
              {{ "MOBILE_USERS.Hunger_Type" | translate }}
            </th>
            <div *ngIf="lang == 'en'">
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.hungerTypeId=='1'">Tummy Making Noises</span>
              <span *ngIf="element.hungerTypeId=='2'">Feeling Chewy</span>
              <span *ngIf="element.hungerTypeId=='3'">Emotional Craves</span>
               <span *ngIf="element.hungerTypeId=='4'">I Don't Know</span>
            </td>
          </div>
          <div *ngIf="lang == 'ar'">
            <td  mat-cell *matCellDef="let element">
              <span *ngIf="element.hungerTypeId=='1'">أصوات تصدر عن البطن</span>
              <span *ngIf="element.hungerTypeId=='2'">شعور مطاطى</span>
              <span *ngIf="element.hungerTypeId=='3'">الرغبة العاطفية</span>
               <span *ngIf="element.hungerTypeId=='4'">لا أعرف</span>
            </td>
          </div>
          </ng-container>
  
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>
              {{ "SHARED.CREATED" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.created | date: "dd-MM-YYYY" }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="mat-row-hover"
            (click)="this.router.navigateByUrl('/Hunger-videos/edit/' + row.id)"
          ></tr>
        </table>
      </div>
  
      <ng-template #noData>
        <app-no-data-placeholder classes="my-3" [data]="dataSource.data"> </app-no-data-placeholder>
      </ng-template>
  
      <mat-paginator
        #paginator
        [length]="totalItems"
        [pageSize]="pageSize"
        [pageSizeOptions]="[1, 5, 10, 25, 100]"
        (page)="onPageChange($event)"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
    </div>
  </div>
  