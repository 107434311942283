<div class="p-3 bg-white mat-elevation-z8">
  <div class="d-flex justify-content-start">
    <div class="flex-grow-1">
  <div class="card">
    <div class="card-header">
      <h1 class="mb-3">{{ "MOBILE_USERS.SEND_NOTIFICATIONS" | translate }}</h1>
    </div>
    <div class="card-body">
      <div class="d-flex align-items-start">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <button style="margin-right: 50px;margin-left: 50px;margin-bottom: 30px;" class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ "MOBILE_USERS.ALL_USERS" | translate }}</button>
          <button style="margin-right: 50px;margin-left: 50px;margin-bottom: 30px;" class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ "MOBILE_USERS.SUBSCRIBED_USERS" | translate }}</button>
          <button style="margin-right: 50px;margin-left: 50px;margin-bottom: 30px;" class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">{{ "MOBILE_USERS.UNSUBSCRIBED_USERS" | translate }}</button>
          <button style="margin-right: 50px;margin-left: 50px;margin-bottom: 30px;" class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">{{ "MOBILE_USERS.SPECIFIC_USER" | translate }}</button>
        </div>
        <div class="tab-content" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
            <div class="container">
              <h2>{{ "MOBILE_USERS.SEND_NOTIFICATION_TO_ALL_USERS" | translate }}</h2>
              <form #form="ngForm" (ngSubmit)="sendNotificationToAllUsers()">
                <div class="fmb-3 mt-3">
                  <label for="title">{{ "ARTICLES.SUBJECT" | translate }}</label>
                  <input type="text" class="form-control" id="titleAll" [(ngModel)]="titleAll" placeholder="" name="titleAll" required>
                </div>
                <div class="mb-3 mt-3">
                  <label for="exampleFormControlTextarea1" class="form-label">{{ "MOBILE_USERS.MESSAGE" | translate }}</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" [(ngModel)]="bodyAll" rows="3" required></textarea>
                </div>
                <button class="button-1" type="submit">{{ "MOBILE_USERS.SEND" | translate }}</button>
              </form>
            </div>
          </div>
          <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            <div class="container">
              <h2>{{ "MOBILE_USERS.SEND_NOTIFICATION_TO_SUBSCRIBED_USERS" | translate }}</h2>
              <form #form="ngForm" (ngSubmit)="sendNotificationToSubscribedUsers()">
                <div class="mb-3 mt-3">
                  <label for="title">{{ "ARTICLES.SUBJECT" | translate }}</label>
                  <input type="text" class="form-control" id="titleSubscribed" [(ngModel)]="titleSubscribed" placeholder="" name="titleSubscribed" required>
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label">{{ "MOBILE_USERS.MESSAGE" | translate }}</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" [(ngModel)]="bodySubscribed" rows="3" required></textarea>
                </div>
                <button class="button-1" type="submit">{{ "MOBILE_USERS.SEND" | translate }}</button>
              </form>
            </div>
          </div>
          <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
            <div class="container">
              <h2>{{ "MOBILE_USERS.SEND_NOTIFICATION_TO_UNSUBSCRIBED_USERS" | translate }}</h2>
              <form #form="ngForm" (ngSubmit)="sendNotificationToUnSubscribedUsers()">
                <div class="mb-3 mt-3">
                  <label for="title">{{ "ARTICLES.SUBJECT" | translate }}</label>
                  <input type="text" class="form-control" id="titleUnSubscribed" [(ngModel)]="titleUnSubscribed" placeholder="" name="titleUnSubscribed" required>
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label">{{ "MOBILE_USERS.MESSAGE" | translate }}</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" [(ngModel)]="bodyUnSubscribed" rows="3" required></textarea>
                </div>
                <button class="button-1" type="submit">{{ "MOBILE_USERS.SEND" | translate }}</button>
              </form>
            </div>
          </div>
          <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
            <div class="container">
              <h2>{{ "MOBILE_USERS.SEND_NOTIFICATION_TO_SPECIFIC_USER" | translate }}</h2>
              <!-- <form #form="ngForm" (ngSubmit)="sendNotificationToSpecificUser()">
                <div class="mb-3 mt-3">
                  <label for="title">Title</label>
                  <input type="text" class="form-control" id="titleSpecificUser" [(ngModel)]="titleSpecificUser" placeholder="Enter title" name="titleSpecificUser" required>
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label">Notification content</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" [(ngModel)]="bodySpecificUser" rows="3" required></textarea>
                </div>
                <button type="submit" class="btn btn-primary">Send</button>
              </form> -->
                <div class="mb-3">
                  <label class="form-label" for="title">{{ "ARTICLES.SUBJECT" | translate }}</label>
                  <input class="form-control" [(ngModel)]="title" id="title" placeholder="" class="form-control">
                </div>
              
                <div class="mb-3">
                  <label class="form-label" for="body">{{ "MOBILE_USERS.MESSAGE" | translate }}:</label>
                  <textarea class="form-control" [(ngModel)]="body" id="body" rows="3" required></textarea>
                  <!-- <input class="form-control" [(ngModel)]="body" id="body" placeholder="" class="form-control"> -->
                </div>
              
                <!-- <div class="form-group col-md-6 mt-3">
                  <label for="hungerType">Name</label>
                  <select class="form-control" id="hungerType" [(ngModel)]=" selectedUsers">
                    <option *ngFor="let user of users" [value]="user.deviceToken">{{ user.name }}</option>
                  </select>
                </div> -->

                <div class="mb-3">
                  <label class="form-label" for="users">{{ "MOBILE_USERS.USER_NAME" | translate }}:</label>
                  <ng-multiselect-dropdown
                  required="true"
                    [placeholder]="lang == 'en'?'Select User':'إختار المستخدم'"
                    [data]="users"
                    [(ngModel)]="selectedUsers"
                    [settings]="dropdownSettings"
                    class="form-control">
                  </ng-multiselect-dropdown>
                </div>
              
                <button (click)="send()" class="button-1">{{ "MOBILE_USERS.SEND" | translate }}</button>                      
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
