<div class="login-form-container">
  <div class="w-50 d-flex flex-column justify-content-start align-items-center">
    <div class="w-50">
      <img class="mb-2" src="../../../../assets/media/login/login-logo.svg" />
    </div>
    <form
      class="w-50 d-flex flex-column align-items-center"
      [formGroup]="resetPasswordForm"
      (submit)="submit()"
    >
      <p
        class="w-100 alert alert-primary"
        *ngIf="showSuccessResetRequestMessage"
      >
        {{
          "your request successfully submitted, please check your email to continue resetting your password"
        }}
      </p>

      <mat-form-field *ngIf="!resetPasswordToken" class="w-100 my-2">
        <mat-error>
          {{ "VALIDATION_ERRORS.REQUIRED" | translate }}
        </mat-error>
        <mat-label>{{ "AUTH.EMAIL" | translate }}</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>

      <div class="d-flex flex-column w-100" *ngIf="resetPasswordToken">
        <mat-form-field class="my-2">
          <mat-error>
            {{ "VALIDATION_ERRORS.REQUIRED" | translate }}
          </mat-error>
          <mat-label>{{ "AUTH.PASSWORD" | translate }}</mat-label>
          <input
            matInput
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            required
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hidePassword = !hidePassword; $event.preventDefault()"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword"
          >
            <mat-icon>{{
              hidePassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field class="my-2">
          <mat-error>
            {{ "VALIDATION_ERRORS.REQUIRED" | translate }}
          </mat-error>
          <mat-label>{{ "AUTH.CONFIRM_PASSWORD" | translate }}</mat-label>
          <input
            matInput
            [type]="hideConfirmPassword ? 'password' : 'text'"
            formControlName="confirmPassword"
            required
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="
              hideConfirmPassword = !hideConfirmPassword;
              $event.preventDefault()
            "
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideConfirmPassword"
          >
            <mat-icon>{{
              hideConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <button
        class="w-100 radius-2"
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="resetPasswordForm.invalid"
      >
        {{ "AUTH.RESET_PASSWORD" | translate }}
      </button>
    </form>
    <div class="mt-1">
      <a href="auth/login">{{ "AUTH.LOGIN" | translate }}</a>
    </div>
  </div>

  <img
    class="login-image"
    src="../../../../assets/media/login/login-image.png"
  />
</div>
