// const baseUrl = 'https://localhost:5033';
// const baseUrl = 'https://dev.api.nutrisha.app';
const baseUrl = 'https://api.nutrisha.app';


export const environment = {
  production: true,
  baseUrl,
  baseAdminV1Url: `${baseUrl}/admin/api/v1/`,
};
