
<div>
      <mat-dialog-content>
        <form [formGroup]="noteForm">
        <h1 mat-dialog-title>{{ 'MOBILE_USERS.Notes_Form'| translate }}</h1>
          <mat-form-field class="w-100">
            <mat-label>{{ 'ARTICLES.SUBJECT' | translate }}</mat-label>
            <input
              required
              formControlName="title"
              matInput
              placeholder="{{ 'ARTICLES.SUBJECT' | translate }}"
            />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>{{ 'SHARED.NOTES' | translate }}</mat-label>
            <textarea
              rows="10"
              cols="50"
              required
              formControlName="notes"
              matInput
            ></textarea>
          </mat-form-field>
          <!-- <app-rich-text
          #richText
          class="my-1 w-100"
          [currentLang]="selectedLang"
          [readOnly]="viewOnly"
          formControlName="notes"
        >
        </app-rich-text> -->
          <div class="d-flex justify-content-end m-3" *ngxPermissionsOnly="'canAssignPremiumFeaturesToAppUsers'">
            <button mat-raised-button
            color="primary"><span class="text-white" (click)="addnote()">{{actionBtn}}</span></button>
            <button mat-raised-button mat-dialog-close style="margin-left: 8px;">{{ 'SHARED.CLOSE' | translate }}</button>
          </div>
        </form>
        <div class="m-4">
        <mat-list *ngIf="actionBtn != 'Save'">
          <mat-divider></mat-divider>
          <div mat-subheader>Attachments</div>
          <mat-list-item *ngFor="let attachment of attachments">
            <mat-icon mat-list-icon>note</mat-icon>
            <div mat-line>{{attachment.name}}</div>
            <div mat-line> {{attachment.date | date}} </div>
            <button mat-icon-button color="warn" (click)="openTab(attachment.url)"><mat-icon>remove_red_eye</mat-icon></button>
            <a href={{attachment.url}}><mat-icon color="warn">cloud_download</mat-icon></a>
            <div mat-line> <a routerLink ={{attachment.url}}></a> </div>
            <button mat-icon-button color="warn" (click)="deleteUserAttachment(attachment.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>
        </mat-dialog-content>
   
    <!-- <div>
        <mat-toolbar *ngIf="currentFile" class="progress-bar m-3" >
          <mat-progress-bar color="accent" [value]="progress"></mat-progress-bar>
          <span class="progress">{{ progress }}%</span>
        </mat-toolbar>
      
        <mat-form-field>
          <div>
            <mat-toolbar>
              <input matInput [value]="fileName" />
      
              <button
                mat-flat-button
                color="primary"
                [disabled]="!currentFile"
                (click)="upload()"
              >
                Upload
              </button>
            </mat-toolbar>
      
            <input
              type="file"
              id="fileInput"
              (change)="selectFile($event)"
              name="fileInput"
            />
          </div>
        </mat-form-field>
      </div>
      
      <div *ngIf="message" class="message" class="m-3">
        {{ message }}
      </div>
      
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>List of Files</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let file of fileInfos | async" class="m-3">
              <a href="{{ file.url }}">{{ file.name }}</a>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card> -->
  </div>
  
